import { default as _404STh7p7T7N0Meta } from "/app/pages/404.vue?macro=true";
import { default as careersAMoZBZaCGvMeta } from "/app/pages/about-us/careers.vue?macro=true";
import { default as indexWvYL5MPcukMeta } from "/app/pages/about-us/index.vue?macro=true";
import { default as why_45rollinVkJN6qWDvgMeta } from "/app/pages/about-us/why-rollin.vue?macro=true";
import { default as haribov1N7MdgW98Meta } from "/app/pages/case-studies/haribo.vue?macro=true";
import { default as indexAptYdJe7z2Meta } from "/app/pages/case-studies/index.vue?macro=true";
import { default as jbconseilaleDFcplgCMeta } from "/app/pages/case-studies/jbconseil.vue?macro=true";
import { default as nsi_45solutions_45cpa005dWsrGf5UJlMeta } from "/app/pages/case-studies/nsi-solutions-cpa005.vue?macro=true";
import { default as webcampusBannI2kMecMeta } from "/app/pages/case-studies/webcampus.vue?macro=true";
import { default as contactV6oPJjs9RyMeta } from "/app/pages/contact.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as loginhHM0vSTW5jMeta } from "/app/pages/login.vue?macro=true";
import { default as _91slug_93qrOrxTbya4Meta } from "/app/pages/resources/[slug].vue?macro=true";
import { default as index6TJluURa3EMeta } from "/app/pages/resources/index.vue?macro=true";
import { default as artificial_45intelligenceujqnd3qAT3Meta } from "/app/pages/services/artificial-intelligence.vue?macro=true";
import { default as automationbKTaxkP0usMeta } from "/app/pages/services/automation.vue?macro=true";
import { default as cloud_45infrastructurecuheV1zBXdMeta } from "/app/pages/services/cloud-infrastructure.vue?macro=true";
import { default as craft_45cms_45developmentoUK6xtyzBqMeta } from "/app/pages/services/craft-cms-development.vue?macro=true";
import { default as custom_45interfacesdqKjsjT0byMeta } from "/app/pages/services/custom-interfaces.vue?macro=true";
import { default as custom_45web_45developmentntDWFFnFAjMeta } from "/app/pages/services/custom-web-development.vue?macro=true";
import { default as design_45and_45creativeuY16CMwW5fMeta } from "/app/pages/services/design-and-creative.vue?macro=true";
import { default as drupal_45developmentiXVbsaEIEaMeta } from "/app/pages/services/drupal-development.vue?macro=true";
import { default as email_45journeysrNafHMlmySMeta } from "/app/pages/services/email-journeys.vue?macro=true";
import { default as indexmjydbmT8M8Meta } from "/app/pages/services/index.vue?macro=true";
import { default as mobile_45appsJxZZCFonNAMeta } from "/app/pages/services/mobile-apps.vue?macro=true";
import { default as optimizely_45cms_45developmentVjdjWWAmNfMeta } from "/app/pages/services/optimizely-cms-development.vue?macro=true";
import { default as vr_45experiencesdwP8X84SMOMeta } from "/app/pages/services/vr-experiences.vue?macro=true";
import { default as component_45stubnO9MU04yTUMeta } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubnO9MU04yTU } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "404___en",
    path: "/404",
    component: () => import("/app/pages/404.vue")
  },
  {
    name: "404___fr",
    path: "/fr/404",
    component: () => import("/app/pages/404.vue")
  },
  {
    name: "about-us-careers___en",
    path: "/about-us/careers",
    component: () => import("/app/pages/about-us/careers.vue")
  },
  {
    name: "about-us-careers___fr",
    path: "/fr/apropos/carrieres",
    component: () => import("/app/pages/about-us/careers.vue")
  },
  {
    name: "about-us___en",
    path: "/about-us",
    component: () => import("/app/pages/about-us/index.vue")
  },
  {
    name: "about-us___fr",
    path: "/fr/apropos",
    component: () => import("/app/pages/about-us/index.vue")
  },
  {
    name: "about-us-why-rollin___en",
    path: "/about-us/why-rollin",
    component: () => import("/app/pages/about-us/why-rollin.vue")
  },
  {
    name: "about-us-why-rollin___fr",
    path: "/fr/apropos/pourquoi-choisir-rollin",
    component: () => import("/app/pages/about-us/why-rollin.vue")
  },
  {
    name: "case-studies-haribo___en",
    path: "/case-studies/haribo",
    component: () => import("/app/pages/case-studies/haribo.vue")
  },
  {
    name: "case-studies-haribo___fr",
    path: "/fr/etudes-de-cas/haribo",
    component: () => import("/app/pages/case-studies/haribo.vue")
  },
  {
    name: "case-studies___en",
    path: "/case-studies",
    component: () => import("/app/pages/case-studies/index.vue")
  },
  {
    name: "case-studies___fr",
    path: "/fr/etudes-de-cas",
    component: () => import("/app/pages/case-studies/index.vue")
  },
  {
    name: "case-studies-jbconseil___en",
    path: "/case-studies/jbconseil",
    component: () => import("/app/pages/case-studies/jbconseil.vue")
  },
  {
    name: "case-studies-jbconseil___fr",
    path: "/fr/etudes-de-cas/jbconseil",
    component: () => import("/app/pages/case-studies/jbconseil.vue")
  },
  {
    name: "case-studies-nsi-solutions-cpa005___en",
    path: "/case-studies/nsi-solutions-cpa005",
    component: () => import("/app/pages/case-studies/nsi-solutions-cpa005.vue")
  },
  {
    name: "case-studies-nsi-solutions-cpa005___fr",
    path: "/fr/etudes-de-cas/nsi-solutions-acp005",
    component: () => import("/app/pages/case-studies/nsi-solutions-cpa005.vue")
  },
  {
    name: "case-studies-webcampus___en",
    path: "/case-studies/webcampus",
    component: () => import("/app/pages/case-studies/webcampus.vue")
  },
  {
    name: "case-studies-webcampus___fr",
    path: "/fr/etudes-de-cas/webcampus",
    component: () => import("/app/pages/case-studies/webcampus.vue")
  },
  {
    name: "contact___en",
    path: "/contact",
    component: () => import("/app/pages/contact.vue")
  },
  {
    name: "contact___fr",
    path: "/fr/contact",
    component: () => import("/app/pages/contact.vue")
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "index___fr",
    path: "/fr",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "login___en",
    path: "/login",
    component: () => import("/app/pages/login.vue")
  },
  {
    name: "login___fr",
    path: "/fr/login",
    component: () => import("/app/pages/login.vue")
  },
  {
    name: "resources-slug___en",
    path: "/resources/:slug()",
    component: () => import("/app/pages/resources/[slug].vue")
  },
  {
    name: "resources-slug___fr",
    path: "/fr/resources/:slug()",
    component: () => import("/app/pages/resources/[slug].vue")
  },
  {
    name: "resources___en",
    path: "/resources",
    component: () => import("/app/pages/resources/index.vue")
  },
  {
    name: "resources___fr",
    path: "/fr/ressources",
    component: () => import("/app/pages/resources/index.vue")
  },
  {
    name: "services-artificial-intelligence___en",
    path: "/services/artificial-intelligence",
    component: () => import("/app/pages/services/artificial-intelligence.vue")
  },
  {
    name: "services-artificial-intelligence___fr",
    path: "/fr/services/intelligence-artificielle",
    component: () => import("/app/pages/services/artificial-intelligence.vue")
  },
  {
    name: "services-automation___en",
    path: "/services/automation",
    component: () => import("/app/pages/services/automation.vue")
  },
  {
    name: "services-automation___fr",
    path: "/fr/services/automatisation",
    component: () => import("/app/pages/services/automation.vue")
  },
  {
    name: "services-cloud-infrastructure___en",
    path: "/services/cloud-infrastructure",
    component: () => import("/app/pages/services/cloud-infrastructure.vue")
  },
  {
    name: "services-cloud-infrastructure___fr",
    path: "/fr/services/infrastructure-cloud",
    component: () => import("/app/pages/services/cloud-infrastructure.vue")
  },
  {
    name: "services-craft-cms-development___en",
    path: "/services/craft-cms-development",
    component: () => import("/app/pages/services/craft-cms-development.vue")
  },
  {
    name: "services-craft-cms-development___fr",
    path: "/fr/services/developpement-craft-cms",
    component: () => import("/app/pages/services/craft-cms-development.vue")
  },
  {
    name: "services-custom-interfaces___en",
    path: "/services/custom-interfaces",
    component: () => import("/app/pages/services/custom-interfaces.vue")
  },
  {
    name: "services-custom-interfaces___fr",
    path: "/fr/services/interfaces-de-gestion",
    component: () => import("/app/pages/services/custom-interfaces.vue")
  },
  {
    name: "services-custom-web-development___en",
    path: "/services/custom-web-development",
    component: () => import("/app/pages/services/custom-web-development.vue")
  },
  {
    name: "services-custom-web-development___fr",
    path: "/fr/services/developpement-web",
    component: () => import("/app/pages/services/custom-web-development.vue")
  },
  {
    name: "services-design-and-creative___en",
    path: "/services/design-and-creative",
    component: () => import("/app/pages/services/design-and-creative.vue")
  },
  {
    name: "services-design-and-creative___fr",
    path: "/fr/services/design-et-creatif",
    component: () => import("/app/pages/services/design-and-creative.vue")
  },
  {
    name: "services-drupal-development___en",
    path: "/services/drupal-development",
    component: () => import("/app/pages/services/drupal-development.vue")
  },
  {
    name: "services-drupal-development___fr",
    path: "/fr/services/developpement-drupal",
    component: () => import("/app/pages/services/drupal-development.vue")
  },
  {
    name: "services-email-journeys___en",
    path: "/services/email-journeys",
    component: () => import("/app/pages/services/email-journeys.vue")
  },
  {
    name: "services-email-journeys___fr",
    path: "/fr/services/automatisation-des-courriels",
    component: () => import("/app/pages/services/email-journeys.vue")
  },
  {
    name: "services___en",
    path: "/services",
    component: () => import("/app/pages/services/index.vue")
  },
  {
    name: "services___fr",
    path: "/fr/services",
    component: () => import("/app/pages/services/index.vue")
  },
  {
    name: "services-mobile-apps___en",
    path: "/services/mobile-apps",
    component: () => import("/app/pages/services/mobile-apps.vue")
  },
  {
    name: "services-mobile-apps___fr",
    path: "/fr/services/applications-mobile",
    component: () => import("/app/pages/services/mobile-apps.vue")
  },
  {
    name: "services-optimizely-cms-development___en",
    path: "/services/optimizely-cms-development",
    component: () => import("/app/pages/services/optimizely-cms-development.vue")
  },
  {
    name: "services-optimizely-cms-development___fr",
    path: "/fr/services/developpement-optimizely-cms",
    component: () => import("/app/pages/services/optimizely-cms-development.vue")
  },
  {
    name: "services-vr-experiences___en",
    path: "/services/vr-experiences",
    component: () => import("/app/pages/services/vr-experiences.vue")
  },
  {
    name: "services-vr-experiences___fr",
    path: "/fr/services/experiences-ar-vr",
    component: () => import("/app/pages/services/vr-experiences.vue")
  },
  {
    name: "resources-en-ai-and-machine-learning-integration-drupal-11___en",
    path: "/resources/ai-and-machine-learning-integration-drupal-11",
    component: () => import("/app/pages/resources/[slug].vue")
  },
  {
    name: "resources-en-ai-and-machine-learning-integration-drupal-11___fr",
    path: "/fr/resources/ai-and-machine-learning-integration-drupal-11",
    component: () => import("/app/pages/resources/[slug].vue")
  },
  {
    name: "resources-fr-integration-de-lia-et-de-lapprentissage-automatique-dans-drupal-11___en",
    path: "/ressources/integration-de-lia-et-de-lapprentissage-automatique-dans-drupal-11",
    component: () => import("/app/pages/resources/[slug].vue")
  },
  {
    name: "resources-fr-integration-de-lia-et-de-lapprentissage-automatique-dans-drupal-11___fr",
    path: "/fr/ressources/integration-de-lia-et-de-lapprentissage-automatique-dans-drupal-11",
    component: () => import("/app/pages/resources/[slug].vue")
  },
  {
    name: "resources-en-importance-project-brief-web-development-building-your-digital-home___en",
    path: "/resources/importance-project-brief-web-development-building-your-digital-home",
    component: () => import("/app/pages/resources/[slug].vue")
  },
  {
    name: "resources-en-importance-project-brief-web-development-building-your-digital-home___fr",
    path: "/fr/resources/importance-project-brief-web-development-building-your-digital-home",
    component: () => import("/app/pages/resources/[slug].vue")
  },
  {
    name: "resources-fr-limportance-dun-cahier-des-charges-dans-le-developpement-web-construire-votre-maison___en",
    path: "/ressources/limportance-dun-cahier-des-charges-dans-le-developpement-web-construire-votre-maison",
    component: () => import("/app/pages/resources/[slug].vue")
  },
  {
    name: "resources-fr-limportance-dun-cahier-des-charges-dans-le-developpement-web-construire-votre-maison___fr",
    path: "/fr/ressources/limportance-dun-cahier-des-charges-dans-le-developpement-web-construire-votre-maison",
    component: () => import("/app/pages/resources/[slug].vue")
  },
  {
    name: "resources-en-your-website-accessible-heres-why-it-matters___en",
    path: "/resources/your-website-accessible-heres-why-it-matters",
    component: () => import("/app/pages/resources/[slug].vue")
  },
  {
    name: "resources-en-your-website-accessible-heres-why-it-matters___fr",
    path: "/fr/resources/your-website-accessible-heres-why-it-matters",
    component: () => import("/app/pages/resources/[slug].vue")
  },
  {
    name: "resources-fr-votre-site-web-est-il-accessible-decouvrez-limportance-de-laccessibilite-de-linternet___en",
    path: "/ressources/votre-site-web-est-il-accessible-decouvrez-limportance-de-laccessibilite-de-linternet",
    component: () => import("/app/pages/resources/[slug].vue")
  },
  {
    name: "resources-fr-votre-site-web-est-il-accessible-decouvrez-limportance-de-laccessibilite-de-linternet___fr",
    path: "/fr/ressources/votre-site-web-est-il-accessible-decouvrez-limportance-de-laccessibilite-de-linternet",
    component: () => import("/app/pages/resources/[slug].vue")
  },
  {
    name: "resources-en-rollins-partnership-portfolio___en",
    path: "/resources/rollins-partnership-portfolio",
    component: () => import("/app/pages/resources/[slug].vue")
  },
  {
    name: "resources-en-rollins-partnership-portfolio___fr",
    path: "/fr/resources/rollins-partnership-portfolio",
    component: () => import("/app/pages/resources/[slug].vue")
  },
  {
    name: "resources-fr-le-panorama-des-partenariats-de-rollin___en",
    path: "/ressources/le-panorama-des-partenariats-de-rollin",
    component: () => import("/app/pages/resources/[slug].vue")
  },
  {
    name: "resources-fr-le-panorama-des-partenariats-de-rollin___fr",
    path: "/fr/ressources/le-panorama-des-partenariats-de-rollin",
    component: () => import("/app/pages/resources/[slug].vue")
  },
  {
    name: "resources-en-drupal-7-drupal-10-migration-preparing-2025-eol___en",
    path: "/resources/drupal-7-drupal-10-migration-preparing-2025-eol",
    component: () => import("/app/pages/resources/[slug].vue")
  },
  {
    name: "resources-en-drupal-7-drupal-10-migration-preparing-2025-eol___fr",
    path: "/fr/resources/drupal-7-drupal-10-migration-preparing-2025-eol",
    component: () => import("/app/pages/resources/[slug].vue")
  },
  {
    name: "resources-fr-la-migration-de-drupal-7-drupal-10-la-preparation-la-fin-de-vie-pour-2025___en",
    path: "/ressources/la-migration-de-drupal-7-drupal-10-la-preparation-la-fin-de-vie-pour-2025",
    component: () => import("/app/pages/resources/[slug].vue")
  },
  {
    name: "resources-fr-la-migration-de-drupal-7-drupal-10-la-preparation-la-fin-de-vie-pour-2025___fr",
    path: "/fr/ressources/la-migration-de-drupal-7-drupal-10-la-preparation-la-fin-de-vie-pour-2025",
    component: () => import("/app/pages/resources/[slug].vue")
  },
  {
    name: "resources-en-information-security-standards-soc-2-pci-dss-and-iso-27001-explained___en",
    path: "/resources/information-security-standards-soc-2-pci-dss-and-iso-27001-explained",
    component: () => import("/app/pages/resources/[slug].vue")
  },
  {
    name: "resources-en-information-security-standards-soc-2-pci-dss-and-iso-27001-explained___fr",
    path: "/fr/resources/information-security-standards-soc-2-pci-dss-and-iso-27001-explained",
    component: () => import("/app/pages/resources/[slug].vue")
  },
  {
    name: "resources-fr-normes-de-securite-des-systemes-dinformation-soc-2-pci-dss-et-iso-27001-sous-la-loupe___en",
    path: "/ressources/normes-de-securite-des-systemes-dinformation-soc-2-pci-dss-et-iso-27001-sous-la-loupe",
    component: () => import("/app/pages/resources/[slug].vue")
  },
  {
    name: "resources-fr-normes-de-securite-des-systemes-dinformation-soc-2-pci-dss-et-iso-27001-sous-la-loupe___fr",
    path: "/fr/ressources/normes-de-securite-des-systemes-dinformation-soc-2-pci-dss-et-iso-27001-sous-la-loupe",
    component: () => import("/app/pages/resources/[slug].vue")
  },
  {
    name: "resources-en-unveiling-drupals-future-portland___en",
    path: "/resources/unveiling-drupals-future-portland",
    component: () => import("/app/pages/resources/[slug].vue")
  },
  {
    name: "resources-en-unveiling-drupals-future-portland___fr",
    path: "/fr/resources/unveiling-drupals-future-portland",
    component: () => import("/app/pages/resources/[slug].vue")
  },
  {
    name: "resources-fr-le-futur-de-drupal-devoile-portland___en",
    path: "/ressources/le-futur-de-drupal-devoile-portland",
    component: () => import("/app/pages/resources/[slug].vue")
  },
  {
    name: "resources-fr-le-futur-de-drupal-devoile-portland___fr",
    path: "/fr/ressources/le-futur-de-drupal-devoile-portland",
    component: () => import("/app/pages/resources/[slug].vue")
  },
  {
    name: "resources-en-impacts-quebecs-law-25-businesses___en",
    path: "/resources/impacts-quebecs-law-25-businesses",
    component: () => import("/app/pages/resources/[slug].vue")
  },
  {
    name: "resources-en-impacts-quebecs-law-25-businesses___fr",
    path: "/fr/resources/impacts-quebecs-law-25-businesses",
    component: () => import("/app/pages/resources/[slug].vue")
  },
  {
    name: "resources-fr-la-loi-25-du-quebec-implications-et-obligations-pour-les-entreprises___en",
    path: "/ressources/la-loi-25-du-quebec-implications-et-obligations-pour-les-entreprises",
    component: () => import("/app/pages/resources/[slug].vue")
  },
  {
    name: "resources-fr-la-loi-25-du-quebec-implications-et-obligations-pour-les-entreprises___fr",
    path: "/fr/ressources/la-loi-25-du-quebec-implications-et-obligations-pour-les-entreprises",
    component: () => import("/app/pages/resources/[slug].vue")
  },
  {
    name: "resources-en-webapps-vs-website-which-one-right-your-business___en",
    path: "/resources/webapps-vs-website-which-one-right-your-business",
    component: () => import("/app/pages/resources/[slug].vue")
  },
  {
    name: "resources-en-webapps-vs-website-which-one-right-your-business___fr",
    path: "/fr/resources/webapps-vs-website-which-one-right-your-business",
    component: () => import("/app/pages/resources/[slug].vue")
  },
  {
    name: "resources-fr-les-applications-web-ou-les-sites-web-dilemme-pour-les-entreprises___en",
    path: "/ressources/les-applications-web-ou-les-sites-web-dilemme-pour-les-entreprises",
    component: () => import("/app/pages/resources/[slug].vue")
  },
  {
    name: "resources-fr-les-applications-web-ou-les-sites-web-dilemme-pour-les-entreprises___fr",
    path: "/fr/ressources/les-applications-web-ou-les-sites-web-dilemme-pour-les-entreprises",
    component: () => import("/app/pages/resources/[slug].vue")
  },
  {
    name: "resources-en-white-crow-re-branding-rollin___en",
    path: "/resources/white-crow-re-branding-rollin",
    component: () => import("/app/pages/resources/[slug].vue")
  },
  {
    name: "resources-en-white-crow-re-branding-rollin___fr",
    path: "/fr/resources/white-crow-re-branding-rollin",
    component: () => import("/app/pages/resources/[slug].vue")
  },
  {
    name: "resources-fr-white-crow-devient-maintenant-rollin___en",
    path: "/ressources/white-crow-devient-maintenant-rollin",
    component: () => import("/app/pages/resources/[slug].vue")
  },
  {
    name: "resources-fr-white-crow-devient-maintenant-rollin___fr",
    path: "/fr/ressources/white-crow-devient-maintenant-rollin",
    component: () => import("/app/pages/resources/[slug].vue")
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/index-sitemap.xml",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/en-CA-sitemap.xml",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/fr-CA-sitemap.xml",
    component: component_45stubnO9MU04yTU
  }
]
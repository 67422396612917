
// @ts-nocheck


export const localeCodes =  [
  "en",
  "fr"
]

export const localeLoaders = {
  "en": [],
  "fr": []
}

export const vueI18nConfigs = [
  () => import("../i18n.config.ts?hash=bffaebcb&config=1" /* webpackChunkName: "__i18n_config_ts_bffaebcb" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./i18n.config.ts",
  "locales": [
    {
      "code": "en",
      "language": "en-CA"
    },
    {
      "code": "fr",
      "language": "fr-CA"
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "services/automation": {
      "fr": "/services/automatisation",
      "en": "/services/automation"
    },
    "services/cloud-infrastructure": {
      "fr": "/services/infrastructure-cloud",
      "en": "/services/cloud-infrastructure"
    },
    "services/craft-cms-development": {
      "fr": "/services/developpement-craft-cms",
      "en": "/services/craft-cms-development"
    },
    "services/custom-interfaces": {
      "fr": "/services/interfaces-de-gestion",
      "en": "/services/custom-interfaces"
    },
    "services/custom-web-development": {
      "fr": "/services/developpement-web",
      "en": "/services/custom-web-development"
    },
    "services/drupal-development": {
      "fr": "/services/developpement-drupal",
      "en": "/services/drupal-development"
    },
    "services/design-and-creative": {
      "fr": "/services/design-et-creatif",
      "en": "/services/design-and-creative"
    },
    "services/email-journeys": {
      "fr": "/services/automatisation-des-courriels",
      "en": "/services/email-journeys"
    },
    "services/mobile-apps": {
      "fr": "/services/applications-mobile",
      "en": "/services/mobile-apps"
    },
    "services/vr-experiences": {
      "fr": "/services/experiences-ar-vr",
      "en": "/services/vr-experiences"
    },
    "services/optimizely-cms-development": {
      "fr": "/services/developpement-optimizely-cms",
      "en": "/services/optimizely-cms-development/"
    },
    "services/artificial-intelligence": {
      "fr": "/services/intelligence-artificielle",
      "en": "/services/artificial-intelligence/"
    },
    "case-studies/index": {
      "fr": "/etudes-de-cas",
      "en": "/case-studies"
    },
    "case-studies/haribo": {
      "fr": "/etudes-de-cas/haribo",
      "en": "/case-studies/haribo"
    },
    "case-studies/jbconseil": {
      "fr": "/etudes-de-cas/jbconseil",
      "en": "/case-studies/jbconseil"
    },
    "case-studies/webcampus": {
      "fr": "/etudes-de-cas/webcampus",
      "en": "/case-studies/webcampus"
    },
    "case-studies/nsi-solutions-cpa005": {
      "fr": "/etudes-de-cas/nsi-solutions-acp005",
      "en": "/case-studies/nsi-solutions-cpa005"
    },
    "about-us/index": {
      "fr": "/apropos",
      "en": "/about-us"
    },
    "about-us/careers": {
      "fr": "/apropos/carrieres",
      "en": "/about-us/careers"
    },
    "about-us/why-rollin": {
      "fr": "/apropos/pourquoi-choisir-rollin",
      "en": "/about-us/why-rollin"
    },
    "resources/index": {
      "fr": "/ressources",
      "en": "/resources"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "en",
    "language": "en-CA",
    "files": []
  },
  {
    "code": "fr",
    "language": "fr-CA",
    "files": []
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = true

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
